<template>
  <div class="page-item-wrap educationDetail-wrap">
    <div id="educationDetail-img-con">
      <img
        class="close-icon"
        src="~assets/image/ico/close.svg"
        alt="닫기"
        @click="education()"
      />
      <pinch-zoom id="educationDetail-img">
        <img src="~assets/image/main/sokchoYeosuEducation.png" alt="교육일정표"
      /></pinch-zoom>
    </div>
  </div>
</template>

<script>
import PinchZoom from 'vue-pinch-zoom'
import Vue from 'vue'
Vue.component('pinch-zoom', PinchZoom)
export default {
  props: {
    isJeju: {
      type: Boolean,
    },
    doubleTap: {
      type: Boolean,
      default: true,
    },
  },
  components: { PinchZoom },
  data: () => ({}),
  methods: {
    education() {
      this.$emit('education')
    },
  },
}
</script>

<style>
.pinch-zoom-wrapper {
  background: none !important;
}
.educationDetail-wrap {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
#educationDetail-img-con {
  position: relative;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
#educationDetail-img-con .close-icon {
  width: 50px;
  position: absolute;
  right: 100px;
  top: 0;
  cursor: pointer;
}
#educationDetail-img-con #educationDetail-img {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
@media (max-width: 1024px) {
  #educationDetail-img-con {
    width: 100%;
    height: 100%;
  }
  #educationDetail-img-con .close-icon {
    right: 5vw;
    top: 10vw;
  }
  #educationDetail-img-con #educationDetail-img {
    height: auto;
    width: 100%;
    top: 50%;
  }
}
</style>
